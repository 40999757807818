html, body {
  background-color: #eee !important;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
body {
  margin: 0;
  padding: 0;
}

a, a:link, a:visited, a:hover {
  color: #428bca;
  text-decoration: none;
}

html,
body {
  height: 100%;
  overflow-x:hidden;
  -webkit-overflow-scrolling: touch;
  background: #f5f5f5;
  color: #555;
  font-size: 14px;
  line-height: 21px; }
  html input,
  body input {
    width: 100%; }

a {
  text-decoration: none; }

p {
  margin-bottom: 15px; }

ul {
  list-style: disc;
  margin-left: 40px;
  margin-bottom: 15px; }

h1,
h2 {
  margin-top: 1em;
  margin-bottom: 16px;
  font-weight: bold;
  line-height: 1.4; }

h1 {
  padding-bottom: .3em;
  font-size: 2.25em;
  line-height: 1.2;
  border-bottom: 1px solid #eee; }

h2 {
  padding-bottom: .3em;
  font-size: 1.75em;
  line-height: 1.225;
  border-bottom: 1px solid #eee; }

code {
  padding: 2px 4px;
  border: 1px solid #ccc;
  margin: 0;
  font-size: 85%;
  background-color: #e8e7e7;
  border-radius: 3px;
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace; }

strong {
  font-weight: 600; }
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline; }

  html {
    line-height: 1; }

  ol,
  ul {
    list-style: none; }

  table {
    border-collapse: collapse;
    border-spacing: 0; }

  caption,
  th,
  td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle; }

  q,
  blockquote {
    quotes: none; }

  q:before,
  q:after,
  blockquote:before,
  blockquote:after {
    content: "";
    content: none; }

  a img {
    border: none; }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary {
    display: block; }

.wrapper {
  padding: 20px; }

@media (min-width: 768px) {
  .row {
    display: flex; } }

@media (min-width: 768px) {
  .column {
    width: 50%; }
    .column:first-child {
      margin-right: 20px; } }

input {
  font-size: 13px;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 6px 10px 5px; }
  input:focus {
    outline: none;
    border-color: #aeaeae;
    box-shadow: inset 0 2px 2px #e9e9e9, 0 0 10px 0 rgba(73, 107, 125, 0.3); }

.react-add-to-calendar__button {
  border: none !important;
  background: none !important;
  padding: 10px 3px;
}

@media print {
  @page {
    size: A4 landscape;
    margin: 7mm;
    zoom: 100%;
  }
  body {
    zoom: 100%;;
    transform: scale(1);
  }
  table {
    page-break-inside: avoid;
  }
  html, body {
    background-color: #fff !important;
  }
  .react-add-to-calendar {
    display: none;
  }
  #_hj_feedback_container {
    display: none;
  }
}